import { jget, getParams, nid, post, ol } from '@/lib/utils'
// import { ClientJS } from 'clientjs';
import FingerprintJS from '@fingerprintjs/fingerprintjs'

// Initialize an agent at application startup.
const fpPromise = FingerprintJS.load()
import { db, supabase } from '@/db'
import { _global } from './common_init'
import _ from 'lodash'
// import Cookies from 'js-cookie';

import store from '@/Store'

export async function clearSession(id = store.user?.id, signout = true) {
  if (id) {
    await db.sessions.delete(id)
    await jget(`/session/clear/${id}`)
  }
  if (signout) await supabase.auth.signOut()
}

export async function saveTeam(team) {
  if (!team.team_id) team.team_id = nid()
  //need to rm this team_ crap.. just pass the obj
  var tm = { id: team.team_id, name: team.team_name, open: team.team_open }
  if (team.is_new) {
    await post('/accept', tm)
  }
  // match the existing format..
  await db.sessions.update(store.user.id, {
    team_id: team.team_id,
    team_info: tm,
  })
}

export async function getProfile(email) {
  let { data, error } = await supabase
    .from('profiles')
    .select('*')
    .eq('email', email)
    .single()
  if (error) console.log(error)
  return data
}

function getTeamInfo(team_id, profile = store.profile) {
  return _.first(profile?.teams_info?.filter((i) => i.id == team_id))
}

function getProfileTeams(profile = store.profile) {
  return _.uniq(
    ol(profile?.teams).concat(ol(profile?.teams_info).map((t) => t.id)),
  )
}

export async function refreshAuthSession(server = false) {
  if (server) {
    await supabase.auth.refreshSession()
  }
  _global.session = (await supabase.auth.getSession()).data.session
  return _global.session
}

export default async function getSession(anon = false) {
  var authedSession = await refreshAuthSession()
  var team_id
  var share_id

  if (window.location.pathname.indexOf('/share') > -1) {
    share_id = window.location.pathname.split('/share/')[1].split('/')[0]
    team_id = 'share-' + share_id
  }

  try {
    await db.open()
  } catch (e) {
    console.error(e)
  }

  if (authedSession && !anon) {
    var sesh = {
      id: authedSession.user.app_metadata?.uid ?? authedSession.user.id,
      authed_id: authedSession.user.id,
      anonymous: false,
      email: authedSession.user.email,
      access_token: authedSession.access_token,
      share_id,
    }

    if (!share_id) await setSession(sesh)

    return sesh
  }

  try {
    var session = await await db.sessions
      .orderBy('id')
      .filter((s) => s.anonymous)
      .last()
  } catch (e) {
    console.error(e)
  }

  if (!session) {
    const fp = await fpPromise
    const result = await fp.get()
    var fingerprint = result.visitorId
    var params = getParams()
    console.log('params.sk', params.sk);
    if (!params.sk) session = await jget(`/session/${fingerprint}`)
    if (!session || session == 'error') {
      session = {
        id: nid(),
        team_id: team_id ?? nid(),
        share_id,
        anonymous: true,
        is_new: true,
        fingerprint,
        params: params,
      }
      session.annon_id = session.id
      await post('/session', session)
    }
    await setSession(session)
  }

  session.share_id = share_id

  return session
}

export async function setSession(session) {
  try {
    // await db.open()
    await db.sessions.put(session)
  } catch (e) {
    console.error(e)
  }
}
